import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import swal from "sweetalert2";
import { Form, Errors } from "@formio/react";
import { Loading } from "../../../../common";
import * as formsActions from "../../../../actions";
import * as commonActions from "../../../../../common/actions";
import * as linkActions from "../../../../../formLink/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { showAlertMessage, showToastSuccessNotification } from "../../../../../alerts";
import { ToastContainer, toast } from 'react-toastify';
import _ from "lodash";

import {DATA_SAVED, PQ_FORM_LOG_TYPE, SAVE_AND_SUBMIT_FORM, SAVE_FORM} from "../../../../../../lib/appConstants";

import FormIOUtils from "../../../../utils";
import { FORM_IO_WIZARD_FORM, FORM_IO_NORMAL_FORM } from "../../../../../../lib/appConstants";
import { createFormIoDownloadFileElements } from "../../../../../../utils";

function debounce(func, timeout = 500){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}
class SubmissionView extends React.Component {
  constructor(props) {
    super(props);
    this.locationState = props.location.state || {};
    const { subcontractorId, savedFormId, hcName } = this.locationState;

    this.state = {
      idleTime: 1000 * 60 * 2,
      subcontractorId,
      redirectUrl: this.getRedirectUrl(subcontractorId),
      formInstance: null,
      form: null,
      wizardFormOptions: {},
      normalFormOptions: {},
      formCompleteStatusChecked: false,
      correctTaxId: '',
      savedFormId: savedFormId,
      hcName: hcName
    };

    this.timeInterval = null;
    this.isChange = false;
    this.targetTypes = ['file', 'checkbox', 'select-one', 'select-multiple', 'radio', 'hidden', 'number', 'input'];
    document.addEventListener("keyup", this.eventListener);
    document.addEventListener("change", this.eventChangeListener);
    document.addEventListener("click", this.eventClickListener);
    document.addEventListener("paste", this.eventClipboardListener);
    document.addEventListener("drop", this.eventClipboardListener);
    document.addEventListener("mousedown", this.eventClickListener);
    document.addEventListener("input", this.eventInputListener);
  }

  getRedirectUrl(subcontractorId) {
    return subcontractorId && `/subcontractors/${subcontractorId}`;
  }

  componentDidMount() {
    this.timeInterval = setInterval(this.handleAutoSave, this.state.idleTime);
    if (this.state.subcontractorId) {
      this.fetchSubcontractorTaxId(this.state.subcontractorId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.subcontractorId !== prevState.subcontractorId) {
      this.fetchSubcontractorTaxId(this.state.subcontractorId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.login.profile.RoleID &&
      nextProps.form.form.components &&
      nextProps.submission.submission &&
      nextProps.login.profile
    ) {
      if(nextProps.form.form.display === FORM_IO_WIZARD_FORM){
        this.financialWizardForms(nextProps.form.form,nextProps.login.profile.RoleID);
      }else{
        this.financialForms(nextProps.form.form,nextProps.login.profile.RoleID);
      }
    }

    const displayType = (nextProps.form && nextProps.form.form && nextProps.form.form.display) ? nextProps.form.form.display : null;
    const role = nextProps.profile.Role;
    const commonConditions = nextProps.submission && nextProps.submission.id && !this.state.formCompleteStatusChecked && displayType !== null && role;
    if (commonConditions) {
      this.props.linkActions.getSavedFormsByFormioSubmissionId(
        nextProps.submission.id,
        (data) => {
          if (data && data.data && data.data.savedForms.length) {
            const { subcontractorId: prevSubcontractorId, savedFormId: prevSavedFormId, hcName: prevHcName } = this.state;
            const formStatus = data.data.savedForms[0].status;
            const formOptions = FormIOUtils.getFormButtonSettings(role, formStatus)

            let stateObject = {
              normalFormOptions: displayType === FORM_IO_NORMAL_FORM ? formOptions : {},
              wizardFormOptions: displayType === FORM_IO_WIZARD_FORM ? formOptions : {},
              formCompleteStatusChecked: true
            };

            if (!prevSubcontractorId ) {
              const subcontractorId = data.data.savedForms[0].subcontractorID;
              stateObject = {
                ...stateObject,
                subcontractorId,
                redirectUrl: this.getRedirectUrl(subcontractorId)
              };
            }

            if(!prevSavedFormId) {
              const savedFormId = data.data.savedForms[0].id;
              stateObject = {
                ...stateObject,
                savedFormId
              };
            }

            if(!prevHcName) {
              const hcName = data.data.savedForms[0].hiringClientName;
              stateObject = {
                ...stateObject,
                hcName
              };
            }

            this.setState(stateObject);
          } else {
            const alertType = "error";
            const alertTitle = "There was an error loading the form";
            showAlertMessage(alertType, alertTitle);
          }
        }
      );
    }
  }

  // Clear interval and event listener
  componentWillUnmount = () => {
    clearInterval(this.timeInterval);
    this.timeInterval = null;
    document.removeEventListener("keyup", this.eventListener);
    document.removeEventListener("change", this.eventChangeListener);
    document.removeEventListener("click", this.eventClickListener);
    document.removeEventListener("paste", this.eventClipboardListener);
    document.removeEventListener("drop", this.eventClipboardListener);
    document.removeEventListener("mousedown", this.eventClickListener);
    document.removeEventListener("input", this.eventInputListener);
  };

  resetAutoSaveTimerDebounce = () => {
    if(this.timeInterval) {
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(this.handleAutoSave, this.state.idleTime);
    }
  };

  resetAutoSaveTimer = debounce(() => this.resetAutoSaveTimerDebounce());

  eventInputListener = (event) => {
    if (event && event.type === 'input' || event && event.target.name === 'data[dateTime]') {
      this.isChange = true;
      this.resetAutoSaveTimer();
    }
  }
  
  eventListener = (event) => {
    if (
      (event.keyCode >= 32 && event.keyCode <= 90) ||
      (event.keyCode >= 97 && event.keyCode <= 122) ||
      event.keyCode === 8 || event.keyCode === 46
    ) {
      this.isChange = true;
      this.resetAutoSaveTimer();
    }
  };
  
  eventChangeListener = (event)=>{
    if(this.targetTypes.includes(event.target.type) || event.target.name === 'data[dateTime]') {
      this.isChange = true;
      this.resetAutoSaveTimer();
    }
  }
  
  eventClickListener = (event) =>{
    const { target } = event;
    if(target) {
      // Check if clicked target is remove button, signature-pad-canvas or a draggable table row
      const isDraggable = [...target.classList].some(className =>
        className.startsWith('formio-drag-button')
      );

      const classesToCheck = [
        'fa fa-remove',
        'btn btn-default formio-button-remove-row',
        'fa fa-times-circle-o',
        'btn btn-danger btn-sm removeRow',
        'fa fa-trash',
        'signature-pad-canvas'
      ];
      if (classesToCheck.includes(target['classList'].value) || isDraggable) {
        this.isChange = true;
        this.resetAutoSaveTimer();
      }
    }
  }
  
  eventClipboardListener = (event) => {
    this.isChange = true;
    this.resetAutoSaveTimer();
  }
  
  onSortEnd = ({oldIndex, newIndex}) => {
    if (oldIndex !== newIndex) {
      this.isChange = true;
      this.resetAutoSaveTimer();
    }
  }

  handleAutoSave = async () => {
    this.handleDraft(true, false);
  };

  logSubmission = ({ isComplete }) => {
    const { subcontractorId, savedFormId } = this.state;

    this.props.linkActions.addSubcontractorFormsLog({
      subcontractorId,
      savedFormId,
      eventType: isComplete ? SAVE_AND_SUBMIT_FORM : SAVE_FORM,
      logType: PQ_FORM_LOG_TYPE
    });
  };

  fetchSubcontractorTaxId = async (subcontractorID) => {
    try {
      this.props.linkActions.getSubcontractorTaxId(
        { 
          subcontractorId: subcontractorID, 
          callback: (data, error) => {
            if (error) {
              return;
            }
            if (data) {
              this.setState({ correctTaxId: data });
            }
          } 
        }
      );
    } catch (error) {
      console.error('An unexpected error occurred:', error);
    }
  }

  onDraft = (error, toast = true) => {
    const alertConfig = {
      type: error ? "error" : "success",
      title: error ? "There was an error saving the form." : DATA_SAVED,
    };

    if (!error) {
      showToastSuccessNotification(alertConfig.title);
    } else {

    if (!toast && this.state.redirectUrl)
      alertConfig.callback = () => {
        this.props.history.push(this.state.redirectUrl);
      };
      showAlertMessage(alertConfig.type, alertConfig.title, alertConfig.callback);
    }
    
  };

  handleDraft = async (toast = true, loadingMarker = true) => {
    if (this.isChange) {
      this.isChange = false;
      loadingMarker && this.props.commonActions.setLoading(true);
      const { savedFormId } = this.state;
      const { _id: formId, name: formName } = this.props.form.form;
      const { submission } = this.props.submission;
      let logError = null;
  
      if (submission && submission.data) {
        const { _id: submissionId } = submission || {};
        await this.props.actions.saveSubmission(
          submission.data,
          formId,
          formName,
          async (err, submissionResult) => {
            if (err) logError = err;
  
            if (submissionResult && savedFormId) {
              await this.props.linkActions.updateSavedFormStatus(
                savedFormId,
                "null",
                (_, error) => {
                  if (error) logError = error;
                },
                { formioSubmissionId: submissionResult._id },
                { formioSubmissionData: submissionResult.data }
              );
              this.logSubmission({ isComplete: false });
              this.resetAutoSaveTimer();
            }
          },
          {
            draft: true,
            submissionId,
          }
        );
      } else {
        window.location.reload();
      }
  
      loadingMarker && this.props.commonActions.setLoading(false);
  
      this.props.linkActions.saveFormSubmissionLogs(
        savedFormId,
        submission,
        (_, error) => {
          if (error) logError = error;
        },
        logError
      );
  
      this.onDraft(logError, toast);
    }
  };
  
  
  onCustomEvent = async ({ type: eventType }) => {
    if (eventType === "draft") {
      this.handleDraft();
    }
  };
  findFinFile(filesComponents, key, value) {
    filesComponents.some(function iter(item) {
        if (item && item.properties && Object.keys(item.properties).length > 0  && item.properties[key] == value) {
          item.uploadOnly = true
        }
        return Array.isArray(item.components) && item.components.some(iter);
    });
    return filesComponents;
  }
  financialWizardForms(form,userRoleId) {
    if (form.components && userRoleId === 6) {
       form.components = this.findFinFile(form.components, 'isFinancialInformation', "true");
    }
    this.setState({ ...this.state, form });
  }
  financialForms(form,userRoleId) {
    if (form.components && userRoleId === 6) {
      for (let i = 0; i < form.components.length; i++) {
        if (form.components[i].properties.isFinancialInformation === "true") {
          form.components[i].uploadOnly = true;
        }
      }
    }
    this.setState({ ...this.state, form });
  }

  onSubmit = (submission) => {
    this.isChange = false;
    const { _id: formId, name: formName } = this.props.form.form;

    const { hcName, savedFormId } = this.state;

    this.props.commonActions.setLoading(true);
    this.props.actions.saveSubmission(
      submission.data,
      formId,
      formName,
      (err, submissionResult) => {
        if (!err) {
          // this.props.history.push(`/${this.props.formioForms.form._id? `formio/forms/${this.props.formioForms.form._id}/submission` : `${this.props.formioForms.form.name}`}/${submission._id}`);
          if (savedFormId) {
            linkActions.markSavedFormAsComplete(
              savedFormId,
              submissionResult.data._id || submissionResult._id,
              this.props.login,
              submission.data,
              (completeRes) => {
                this.props.commonActions.setLoading(false);
                if (completeRes.success && completeRes.data.formUpdated) {
                  this.logSubmission({ isComplete: true });
                  let type = "success",
                    title = `This form was successfully submitted. ${hcName} has been notified. No further action is required at this time.`;
                  showAlertMessage(type, title, () => {
                    this.state.redirectUrl &&
                      this.props.history.push(this.state.redirectUrl);
                  });
                } else {
                  let type = "error",
                    title = "There was an error submitting the form";
                  showAlertMessage(type, title);
                }
              }
            );
          } else {
            this.props.commonActions.setLoading(false);
            alert("Submission Saved");
          }
        } else {
          this.props.commonActions.setLoading(false);
        }
      },
      {
        submissionId: submission._id,
      }
    );
  };

  pathArray = [];
  
  getTaxFieldsPath (obj, name, val) {
    this.pathArray = [];
    this.parseFormioFormMetadata(obj, name, val);
    return this.pathArray;
  }

  parseFormioFormMetadata = (obj, name, val, currentPath) => {
    currentPath = currentPath || "";

    let matchingPath;

    if (!obj || typeof obj !== "object") return;

    if (obj[name] && obj[name].includes(val)){
      this.pathArray.push(`${currentPath}['${name}']`)
    }

    for (const key of Object.keys(obj)) {
      if (key === name && obj[key] === val) {
        matchingPath = currentPath;
      } else {
        matchingPath = this.parseFormioFormMetadata(
          obj[key],
          name,
          val,
          `${currentPath}['${key}']`
        );
      }
      if (matchingPath) break;
    }
    return matchingPath;
  };
  scrollToTop = (e) => {
    document.querySelector(`#main`).scrollIntoView({
      behavior: "smooth",
    });
  };

  onRender = ()=> {
    createFormIoDownloadFileElements();
  }
  render() {
    const { hideComponents } = this.props;
    const { wizardFormOptions, normalFormOptions } = this.state;
    let formOptions = {
      template: "bootstrap3",
      iconset: "fa",
      buttonSettings: { },
      hide: {},
      readOnly: true,
    };

    // hide submit button for wizard forms
    formOptions.buttonSettings.showSubmit = !wizardFormOptions.hideSubmit;

    //hidden submit button, only works in normal forms, not in wizard
    formOptions.hide.submit = normalFormOptions.hideSubmit;

    //hidden save button, only works in normal forms, not in wizard
    formOptions.hide.save = normalFormOptions.hideSave;

    formOptions.readOnly = normalFormOptions.readOnly || wizardFormOptions.readOnly;

    if (
      this.props.form.form.components &&
      this.props.form.form.components.length > 0
    ) {
      let path = this.getTaxFieldsPath(
        this.props.form.form,
        "key",
        "CompanyTaxID"
      );
      if (path) {
        path.forEach((componentPath) => {
          componentPath = componentPath.replace("['key']", "");
          
          let componentProperties = _.get(this.props.formioForms, `form${componentPath}.properties`);
          window.correctTaxId = this.state.correctTaxId.TaxID;
          let customValidation = `if (input) {
            let expectedValue = window.correctTaxId;
            if (input.replace('_', '') === expectedValue) {
              valid = true;
            } else {
              valid = '${componentProperties.taxID}';
            }
          }`;
    
          _.set(this.props.formioForms, `form${componentPath}.validate.custom`, customValidation);
          
        });
      }
    }

    if (
      this.props.formioSubmission &&
      this.props.formioSubmission.submission &&
      this.props.profile.Role &&
      this.state.form &&
      this.state.formCompleteStatusChecked
    ) {
      return (
        <div>
          <h3>View {this.props.form.form.title} Submission</h3>
          <Errors
            errors={[this.props.form.error, this.props.submission.error]}
          />
          {
            (Object.keys(normalFormOptions).length > 0 || Object.keys(wizardFormOptions).length > 0) &&
            <Form
              onCustomEvent={this.onCustomEvent}
              form={this.state.form}
              submission={this.props.submission.submission}
              setSubmission={this.setSubmission}
              url={this.props.submission.url}
              hideComponents={hideComponents}
              onSubmit={this.onSubmit}
              options={formOptions}
              onNextPage={(e) => this.scrollToTop(e)}
              onPrevPage={(e) => this.scrollToTop(e)}
              onRender={(e) => this.onRender()}
              onChange={() => this.onRender()}
            />
          }
          <ToastContainer />
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}
const mapStateToProps = (state) => {
  return {
    login: state.login,
    profile: state.login.profile,
    formioForms: state.formioForms,
    formioSubmission: state.formioSubmission,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(formsActions, dispatch),
    linkActions: bindActionCreators(linkActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubmissionView)
);
