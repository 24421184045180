exports.processingQueueId = 4
exports.documentProcessingStatus = 5

// Document storage location in fileService 
exports.DOCS_FILE_STORAGE='docs'

// PROFILES IDs
exports.profileHolderAdminID = 10;
exports.profileCFAdmin = 8;

// MAX FILE SIZE FOR UPLOAD
exports.FILE_SIZE = 30;
exports.FILE_SIZE_UNIT = 'MB';
exports.MAX_FILE_SIZE = 30720; // 30 * 1024

// Compliance Status
exports.PROJECT_INSURED_COMPLIANT_STATUS_ID = 1;
exports.PROJECT_INSURED_ESCALATED_STATUS_ID = 2;
exports.PROJECT_INSURED_EXPIRED_STATUS_ID = 3;
exports.PROJECT_INSURED_PENDING_STATUS_ID = 4;
exports.PROJECT_INSURED_REJECTED_STATUS_ID = 5;
exports.PROJECT_INSURED_NON_COMPLIANT_STATUS_ID = 6;
exports.PROJECT_INSURED_EXEMPT_STATUS_ID = 11;
exports.PROJECT_INSURED_COMPLIANT_W_MINOR_DEF_STATUS_ID = 15;
exports.PROJECT_INSURED_ON_HOLD_STATUS_ID = 16;

// CoverageAttribute Status
exports.COVERAGE_ATTRIBUTE_EXEMPT_STATUS_ID = 11;

// Document Status
exports.PENDING_PROCESSING_DOCUMENT_STATUS_ID = 4;
exports.SENT_TO_PROCESSING_DOCUMENT_STATUS_ID = 5;
exports.PROCESSING_IN_PROGRESS_DOCUMENT_STATUS_ID = 10;
exports.PROCESSING_COMPLETE_DOCUMENT_STATUS_ID = 11;
exports.NON_SUBMITTED_DOCUMENT_STATUS_ID = 12;
exports.GARBAGED_DOCUMENT_STATUS_ID = 13;
exports.PENDING_REVIEW_DOCUMENT_STATUS_ID = 14;

// Default Queues
exports.DEFAULT_DATA_ENTRY_QUEUE_ID = 1;
exports.DEFAULT_SENT_TO_PROCESSING_QUEUE_ID = 4;

// Default Document Types
exports.DEFAULT_CERTIFICATE_DOCUMENT_TYPE_ID = 1;
exports.DEFAULT_DOCUMENT_QUEUEID_ID=1;

// PQ User Roles
exports.PQ_ADMIN_ROLE_ID = 1;
exports.PQ_OPERATOR_ROLE_ID = 2;
exports.HIRING_CLIENT_FULL_ACCESS_ROLE_ID = 3;
exports.SUBCONTRACTOR_ROLE_ID = 4;
exports.PQ_FINANCIAL_ANALYST_ROLE_ID = 5;
exports.HIRING_CLIENT_NON_FINANCIAL_ACCESS_ROLE_ID = 6;
exports.SYSTEM_ROLE_ID = 7;
exports.SELF_SERVICE_ADMIN_ROLE_ID= 26;
exports.SELF_SERVICE_OPERATOR_ROLE_ID= 27;
exports.SELF_SERVICE_FINANCIAL_ANALYST_ROLE_ID= 28;
exports.SELF_SERVICE_ANALYST_ROLE_ID= 30;
exports.SUPER_HIRING_CLIENT_FULL_ACCESS_ID= 25;

//PQ User Role Names
exports.PQ_ADMIN_ROLE_NAME = 'PQ Admin';
exports.PQ_OPERATOR_ROLE_NAME = 'PQ Operator';
exports.PQ_FINANCIAL_ANALYST_ROLE_NAME = 'PQ Financial Analyst';
exports.SELF_SERVICE_ADMIN_ROLE_NAME= 'Self-Service Admin';
exports.SELF_SERVICE_OPERATOR_ROLE_NAME= 'Self-Service Operator';
exports.SELF_SERVICE_ANALYST_ROLE_NAME= 'Self-Service Financial Analyst';
exports.SELF_SERVICE_ANALYST_TURNER_ROLE_NAME= 'Self-Service Analyst (Turner)';

// CF User Roles
exports.CF_ADMIN_ROLE_ID = 8;
exports.HOLDER_ADMIN_ROLE_ID = 10;
exports.INSURED_ROLE_ID = 11;
exports.DEPARTMENT_MANAGER_ROLE_ID = 12;
exports.ACCOUNT_MANAGER_ROLE_ID = 13;
exports.PROCESSOR_ROLE_ID = 14;
exports.DATA_ENTRY_CLERK_ROLE_ID = 15;
exports.HOLDER_EXECUTIVE_ROLE_ID = 20;
exports.HOLDER_CLERICAL_ROLE_ID = 21;
exports.HOLDER_VIEW_ONLY_ROLE_ID = 22;
exports.HOLDER_AUDITOR_ROLE_ID = 23;
exports.HOLDER_PROCESSOR_ROLE_ID = 24;
exports.CF_THIRD_PARTY_DATA_ENTRY_ID = 29;

//Subcontractors Fin Info status

exports.PREQUALIFICATION_COMPLETED = 'Prequalification Completed';


// User profiles
exports.HC = 'HC';
exports.SC = 'SC';


// REQUIREMENT SET RULES OPTIONS - BEGIN
// see implementation on nodeapp/src/cf_mssql/processing.js:382
exports.conditionPossibleValues = [
  {label: '-- Condition --', value: ''},
  {label: '= (Checked or unchecked)', value: 1},
  {label: '= (AM Best Rating)', value: 2},
  {label: '>= (AM Best Rating)', value: 3},
  {label: '= (Equals $ Amount)', value: 4},
  {label: '>= (Greater or Equals $ Amount)', value: 5},
  {label: '> (Greater Than $ Amount)', value: 6},
  {label: '<= (Less or Equal $ Amount)', value: 7},
  {label: '= (AM Best Financial Size Category)', value: 8},
  {label: '>= (AM Best Financial Size Category)', value: 9},
  {label: '== (Matches Verbiage)', value: 10},
];

exports.AM_BEST_RATING_TYPE_VALUES = [2, 3];
exports.AM_BEST_FINANCIAL_SIZE_CATEGORY_TYPE_VALUES = [8, 9];

exports.CONDITION_MATCHES_VALUE = 'MATCHES';

exports.valueStatusOptions = [
  {label: '-- Status --', value: ''},
  {label: 'UNCHECKED', value: 'UNCHECKED'},
  {label: 'CHECKED', value: 'CHECKED'},
];
exports.deficiencyTypeOptions = [
  {label: '-- Deficiency Type --', value: ''},
  {label: 'Major', value: 1},
  {label: 'Minor', value: 2},
];
exports.valueRatingOptions = [
  {label: '-- Rating --', value: ''},
  {label: 'A++', value: 'A++'},
  {label: 'A+', value: 'A+'},
  {label: 'A', value: 'A'},
  {label: 'A-', value: 'A-'},
  {label: 'B++', value: 'B++'},
  {label: 'B+', value: 'B+'},
  {label: 'B', value: 'B'},
  {label: 'B-', value: 'B-'},
  {label: 'C++', value: 'C++'},
  {label: 'C+', value: 'C+'},
  {label: 'C', value: 'C'},
  {label: 'C-', value: 'C-'},
  {label: 'D', value: 'D'},
  {label: 'E', value: 'E'},
  {label: 'F', value: 'F'},
  {label: 'S', value: 'S'},
];
exports.AMBestFinancialSizeOptions = [
  { label: '-- AMBest Financial Size --', value: '' },
  { label: 'I', value: 'I' },
  { label: 'II', value: 'II' },
  { label: 'III', value: 'III' },
  { label: 'IV', value: 'IV' },
  { label: 'V', value: 'V' },
  { label: 'VI', value: 'VI' },
  { label: 'VII', value: 'VII' },
  { label: 'VIII', value: 'VIII' },
  { label: 'IX', value: 'IX' },
  { label: 'X', value: 'X' },
  { label: 'XI', value: 'XI' },
  { label: 'XII', value: 'XII' },
  { label: 'XIII', value: 'XIII' },
  { label: 'XIV', value: 'XIV' },
  { label: 'XV', value: 'XV' },
];
// REQUIREMENT SET RULES OPTIONS - END

// Task Status
exports.CERTFOCUS_TASK_PENDING_ID = 1;
exports.CERTFOCUS_TASK_FINISHED_ID = 2;
exports.CERTFOCUS_TASK_OPEN_ID = 3;

// Deficiency Status
exports.DEFICIENCY_CONFIRMED_STATUS_ID = 1;
exports.DEFICIENCY_WAIVED_STATUS_ID = 2;
exports.DEFICIENCY_REJECTED_STATUS_ID = 3;
exports.DEFICIENCY_ESCALATED_STATUS_ID = 4;

//WORKFLOW TYPES
exports.CF_POLICY_VALIDATION_PROCESS = 14;

// Workflow Components
exports.ADD_WAITING_TASK_WF_COMPONENT_ID = 11;
exports.ADD_NON_WAITING_TASK_WF_COMPONENT_ID = 12;
exports.ADD_REMEDIATION_UPDATE_EMAIL_WF_COMPONENT_ID = 13;
exports.ADD_REMEDIATION_CALL_AND_UPDATE_WF_COMPONENT_ID = 14;
exports.ADD_REMEDIATION_FORCE_CALL_WF_COMPONENT_ID = 15;
exports.SEND_MAIL_WF_COMPONENT_ID = 16;
exports.CHECK_STATUS_WF_COMPONENT_ID = 17;
exports.CHANGE_STATUS_WF_COMPONENT_ID = 18;


// CoverageAttribute Status
exports.COVERAGE_ATTRIBUTE_NON_SUBMITTED_STATUS_ID = 1;
exports.COVERAGE_ATTRIBUTE_PENDING_REVIEW_STATUS_ID = 2;
exports.COVERAGE_ATTRIBUTE_ACCEPTED_STATUS_ID = 3;
exports.COVERAGE_ATTRIBUTE_ACCEPTED_W_MINOR_DEF_STATUS_ID = 4;
exports.COVERAGE_ATTRIBUTE_REJECTED_STATUS_ID = 5;
exports.COVERAGE_ATTRIBUTE_REJECTED_EXPIRED_STATUS_ID = 6;
exports.COVERAGE_ATTRIBUTE_ESCALATED_REJECTED_STATUS_ID = 7;
exports.COVERAGE_ATTRIBUTE_ESCALATED_EXPIRED_STATUS_ID = 8;
exports.COVERAGE_ATTRIBUTE_ESCALATED_NON_SUBMITTED_STATUS_ID = 9;
exports.COVERAGE_ATTRIBUTE_WAIVED_STATUS_ID = 10;
exports.COVERAGE_ATTRIBUTE_EXEMPT_STATUS_ID = 11;
exports.COVERAGE_ATTRIBUTE_EXPIRED_STATUS_ID = 12;
exports.COVERAGE_ATTRIBUTE_ESCALATED_STATUS_ID = 13;

exports.ROLES = {
  SELF_SERVICE_OPERATOR:'Self-Service Operator',
  SELF_SERVICE_ANALYST:'Self-Service Analyst (Turner)',
  SELF_SERVICE_ADMIN:'Self-Service Admin',
}

exports.MAGELLAN_QUEUE_ID = 16;
exports.APPROVAL_QUEUE_ID = 22;
exports.SCORE_CARD_CONCEPT_IDS_FOR_LEGAL = 10;
exports.SCORE_CARD_CONCEPT_IDS_FOR_PROFITABILITY = 8;
exports.SCORE_CARD_CONCEPT_IDS_FOR_REFERENCE = 11;
exports.SCORE_CARD_CONCEPT_IDS_FOR_CREDIT_LINE = 9;
exports.SCORE_CARD_CONCEPT_IDS_FOR_CREDIT_HISTORY = 12;

exports.DATA_SAVED = 'Data Successfully Saved.'
exports.DEFAULT_CONNECTION_NAME_KEY = '_SSOSAMLCONN_';
//Task Types
exports.PQ_TASK_TYPE_NOTE = 1;
exports.TEXT_LIMIT = 25;
exports.TEXT_DISPLAY_LIMIT = {
  SEVEN: 7,
  TWENTY_FIVE: 25,
  FIFTY: 50,
  HUNDRED: 100,

};
exports.DEFULT_MODAL_HEIGHT = 400

// Form IO submission statuses
exports.FORM_IO_INCOMPLETE_FORM_STATUS = 'Incomplete';

// Form IO form types
exports.FORM_IO_WIZARD_FORM = 'wizard';
exports.FORM_IO_NORMAL_FORM = 'form';

// Subcontractor status
exports.SUBCONTRATOR_STATUS_NONE = 1
exports.SUBCONTRATOR_STATUS_PREQUALIFICATION_COMPLETED = 6
exports.SUBCONTRATOR_STATUS_PENDING_INVITATION_REMEDIATION = 14
exports.SUBCONTRATOR_STATUS_PENDING_ANALYSIS = 15
exports.SUBCONTRATOR_STATUS_MIGRATION_TEMP = 19
exports.SUBCONTRATOR_STATUS_PENDING_CONVID_FORM = 20
exports.SUBCONTRATOR_STATUS_PENDING_CONVID_UPDATE = 21

// ROLES WITH RESTRICTIONS TO CHANGE subcontractor status
exports.SELF_SERVICE_OPERATOR = 27
exports.SELF_SERVICE_ANALYST = 30

// WORKFLOW COMPONENTS
exports.CF_SEND_MAIL = 16
exports.CF_ADD_WAITING_TASK = 11
exports.CF_ADD_NON_WAITING_TASK = 12
exports.CF_DAYS_UNTIL_VALIDATION = 20
exports.PQ_SEND_EMAIL = 1

// WORKFLOW COMPONENTPRAMETERS
exports.CF_EMAIL_OPTION = 79
exports.CF_MAIL_TEMPLATE = 68
exports.CF_TO_ADDRESS = 67
exports.CF_FROM_EMAIL_ADDRESS = 3
exports.CF_DAYS_UNTIL_VALIDATION_DAYS_PARAMS = 93

exports.PQ_FROM_EMAIL_ADDRESS = 3
exports.PQ_FROM_EMAIL_NAME = 4
exports.PQ_MAIL_TEMPLATE = 1
exports.PQ_ASSIGN_TO_ROLE = 84
exports.PQ_ASSIGN_TO_ID = 85

//CF user roles Names
exports.CF_THIRD_PARTY_DATA_ENTRY_NAME = 'Third Party Data Entry';

exports.LOCAL_STORE_KEY_CURRENT_SYSTEM = 'currentSystem';
exports.PQ_CURRENT_SYSTEM = 'pq';

exports.PROJECT_AI_WORDING = 'Project AI Wording';

// Auth Token Constant
exports.AUTH_TOKEN = 'auth-token';
exports.SSO = 'auth-token';

// Subcontractor Log Type
exports.PQ_FINANCIAL_INFO_LOG_TYPE = 1;
exports.PQ_FORM_LOG_TYPE = 2;

// Event Type for Subcontractor Log
// Types of PQ_FINANCIAL_INFO_LOG_TYPE
exports.SAVE_FIN_INFO = 1;
exports.SAVE_AND_SUBMIT_FIN_INFO = 2;
exports.MARK_INCOMPLETE_FIN_INFO = 3;
exports.FORCE_RENEWAL_FIN_INFO = 4;
exports.FORCE_FINANCIAL_UPDATE_FIN_INFO = 5;

// Types of PQ_FORM_LOG_TYPE
exports.SAVE_FORM = 1;
exports.SAVE_AND_SUBMIT_FORM = 2;
exports.MARK_INCOMPLETE_FORM = 3;

//ASCII Value
exports.BACKSPACE_ASCII = 8;
// 
exports.NOT_CAPTURED = "Not Captured";

//Hash URL Reasponse Code
exports.INSERT_NEW_RECORD = 1;
exports.UPDATE_EXISTING_RECORD = 2;